<script setup lang="ts">
</script>

<template>
  <div>
    <Background :background="'left'" :darken-top="true" />
    <slot />
    <ModalContainer />
  </div>
</template>

<style scoped>
  .pos-abs {
    position: absolute;
  }
</style>